export { default as logo } from "./general/logo.svg";
export { default as logowhite } from "./general/logo-white.svg";
export { default as closeIcon } from "./general/close-icon.svg";
export { default as selectArrow } from "./general/drop-arrow.svg";
export { default as selectArrowWhite } from "./general/drop-arrow-white.svg";
export { default as langicon } from "./general/lang-icon.svg";
export { default as langiconwhite } from "./general/lang-icon-white.svg";
export { default as whiteverticleline } from "./general/white-verticle-line.svg";
export { default as ctastar } from "./general/cta-star.svg";
export { default as brownstar } from "./general/brown-star.svg";
export { default as rightarrow } from "./general/right-arrow.svg";
export { default as leftarrow } from "./general/left-arrow.svg";
export { default as verticleline } from "./general/verticle-line.svg";

// ROOMS IMAGES
export { default as carpark } from "./rooms/vacationplace/car-park.svg";
export { default as laundrymachine } from "./rooms/vacationplace/laundry-machine.svg";
export { default as terrace } from "./rooms/vacationplace/terrace.svg";
export { default as dishwasher } from "./rooms/vacationplace/dishwasher-icon.svg";
export { default as swimmingpool } from "./rooms/vacationplace/swimming-pool.svg";

// BOOKING SVGS
export { default as thankyouicon } from "./booking/tq-done.svg";
export { default as stepcomplete } from "./booking/step-complete.svg";

// HOME SVGS
export { default as abouticon } from "./home/about-icon.svg";

export let images = {
  // GENERAL
  footerBg: {
    img: require("./general/footer-bg.jpg"),
  },

  // BOOKING
  bookingBg: {
    img: require("./booking/booking-bg.jpg"),
  },
  failed: {
    img: require("./booking/failed.png"),
  },

  // HEADER
  homehead: {
    img: require("./header/home-head.jpg"),
  },
  roomshead: {
    img: require("./header/rooms-head.jpg"),
  },
  locationhead: {
    img: require("./header/location-head.jpg"),
  },
  contacthead: {
    img: require("./header/contact-head.jpg"),
  },

  // HOMEPAGE
  bannerPoster: {
    img: require("./home/banner-video-poster.jpg"),
  },
  homeVideo: {
    img: require("./home/home-video.mp4"),
  },
  about1: {
    img: require("./home/about-1.jpg"),
  },
  about2: {
    img: require("./home/about-2.jpg"),
  },
  about3: {
    img: require("./home/about-3.jpg"),
  },
  about4: {
    img: require("./home/about-4.jpg"),
  },
  // HOME > AMENITIES
  activitiesBg: {
    img: require("./home/activities.jpg"),
  },
  activitiesThumb: {
    img: require("./home/activities-thumb.jpg"),
  },
  elegantbedroomsBg: {
    img: require("./home/8-elegant-bedrooms.jpg"),
  },
  elegantbedroomsThumb: {
    img: require("./home/8-elegant-bedrooms-thumb.jpg"),
  },
  grandgardenBg: {
    img: require("./home/grand-garden.jpg"),
  },
  grandgardenThumb: {
    img: require("./home/grand-garden-thumb.jpg"),
  },
  swimmingpoolBg: {
    img: require("./home/swimming-pool.jpg"),
  },
  swimmingpoolThumb: {
    img: require("./home/swimming-pool-thumb.jpg"),
  },
  feature1: {
    img: require("./home/feature-1.jpg"),
  },
  feature2: {
    img: require("./home/feature-2.jpg"),
  },

  // ROOMS IMAGES
  roomsbanner: {
    img: require("./rooms/rooms-banner.jpg"),
  },
  roomsbannermb: {
    img: require("./rooms/rooms-banner-mb.jpg"),
  },
  lounges: {
    img: require("./rooms/amenities/2-large-welcoming-lounges.jpg"),
  },
  modernbath: {
    img: require("./rooms/amenities/6-modern-bathrooms.jpg"),
  },
  bedroom: {
    img: require("./rooms/amenities/8-elegant-bedrooms.jpg"),
  },
  terrace: {
    img: require("./rooms/amenities/an-80-m²-terrace.jpg"),
  },
  kitchen: {
    img: require("./rooms/amenities/fully-equipped-kitchen.jpg"),
  },
  pool: {
    img: require("./rooms/amenities/swimming-pool.jpg"),
  },
  activityBg: {
    img: require("./rooms/activities/activities-bg.jpg"),
  },
  barbecue: {
    img: require("./rooms/activities/barbecue.jpg"),
  },
  foosball: {
    img: require("./rooms/activities/foosball.jpg"),
  },
  petanquecourt: {
    img: require("./rooms/activities/petanque-court.jpg"),
  },
  tabletennis: {
    img: require("./rooms/activities/table-tennis.jpg"),
  },
  gallery1: {
    img: require("./rooms/interiorexterior/gallery-1.jpg"),
  },
  gallery2: {
    img: require("./rooms/interiorexterior/gallery-2.jpg"),
  },
  gallery3: {
    img: require("./rooms/interiorexterior/gallery-3.jpg"),
  },
  gallery4: {
    img: require("./rooms/interiorexterior/gallery-4.jpg"),
  },
  gallery5: {
    img: require("./rooms/interiorexterior/gallery-5.jpg"),
  },
  gallery6: {
    img: require("./rooms/interiorexterior/gallery-6.jpg"),
  },
  gallery7: {
    img: require("./rooms/interiorexterior/gallery-7.jpg"),
  },

  // LOCATION IMAGES
  location1: {
    img: require("./location/location-1.jpg"),
  },
  location2: {
    img: require("./location/location-2.jpg"),
  },
  location3: {
    img: require("./location/location-3.jpg"),
  },

  // CONTACT IMAGES
  contactBg: {
    img: require("./contact/contact-bg.jpg"),
  },
};
