import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import {
  bookingURL,
  conditionsURL,
  contactThankYouURL,
  contactURL,
  homeURL,
  locationURL,
  roomsURL,
  thankyouURL,
} from "./helpers/constant";
import Booking from "./pages/Booking/Booking";
import Header from "./components/Header/Header";
import Failed from "./pages/Failed/Failed";

import Thankyou from "./pages/Booking/Thankyou/Thankyou";
import Footer from "./components/Footer/Footer";
import Rooms from "./pages/Rooms/Rooms";
import Location from "./pages/Location/Location";
import Contact from "./pages/Contact/Contact";
import Conditions from "./pages/Conditions/Conditions";
import NotFound from "./pages/NotFound/NotFound";
import CThankyou from "./pages/ContactThankyou/CThankyou";
// import gsap from "gsap-trial";
// import { ScrollTrigger } from "gsap-trial/ScrollTrigger";
// import { ScrollSmoother } from "gsap-trial/ScrollSmoother";

// gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const Routing = () => {
  // useLayoutEffect(() => {
  //   const container = containerRef.current;
  //   const pinnedContent = pinnedContentRef.current;
  //   ScrollSmoother.create({
  //     smooth: 0,
  //     effects: true,
  //     smoothTouch: 0.1,
  //     normalizeScroll: true,
  //   });
  // }, []);

  return (
    <>
      <Header />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <Routes>
            <Route path={homeURL} element={<Home />} />
            <Route path={roomsURL} element={<Rooms />} />
            <Route path={locationURL} element={<Location />} />
            <Route path={contactURL} element={<Contact />} />
            <Route path={conditionsURL} element={<Conditions />} />
            <Route path={bookingURL} element={<Booking />} />
            <Route path="/failed" element={<Failed />} />

            <Route path={thankyouURL} element={<Thankyou />} />
            <Route path={contactThankYouURL} element={<CThankyou />} />
            <Route exact path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Routing;
