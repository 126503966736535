export const homeURL = "/";
export const roomsURL = "/rooms";
export const locationURL = "/location";
export const contactURL = "/contact-us";
export const bookingURL = "/booking";
export const thankyouURL = "/thankyou/:id";
export const conditionsURL = "/conditions";
export const contactThankYouURL = "/thank-you";

// EXTERNAL LINKS
export const mapDirection =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d712.4207386247705!2d4.8464972696301185!3d44.41915119819225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b571c2f0a550c3%3A0xc4845f68a74b00d!2sMas%20du%20Pont%20de%20la%20Berre!5e0!3m2!1sfr!2sin!4v1732557199433!5m2!1sfr!2sin";

export const mapExternalURL =
  "https://maps.google.com/maps/dir//Mas+du+Pont+de+la+Berre+2175+Rte+de+R%C3%A9auville+26230+Chantemerle-l%C3%A8s-Grignan+France/@44.4191512,4.847141,19z/data=!4m5!4m4!1m0!1m2!1m1!1s0x12b571c2f0a550c3:0xc4845f68a74b00d";

export const addressURL = "";
export const phoneURL = "tel:+330646263861";
export const emailURL = "mailto:masdupontdelaberre@gmail.com";
export const asresoftURL = "https://asresoft.com/";
