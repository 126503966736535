import React from "react";
import "./failed.scss";
import { images } from "../../assets";
import { useLanguage } from "../../LanguageContext";
import { AnimatedCta } from "../../components/AnimatedCta/AnimatedCta";
import { bookingURL } from "../../helpers/constant";

function Failed() {
  const { t } = useLanguage();

  return (
    <>
      <section className="failed">
        <div className="my_container">
          <div className="failed_sec">
            <img
              width={100}
              height={100}
              src={images.failed.img}
              alt="failed icon"
              className="failed_icon"
            />
            <h1 className="title">{t("booking.transFailed")}</h1>
            <p
              className="caption caption2"
              dangerouslySetInnerHTML={{ __html: t("booking.transMessage") }}
            />
            <div className="cta_wrapper">
              <AnimatedCta
                icons
                urlText={t("booking.bookAgain")}
                url={bookingURL}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Failed;
