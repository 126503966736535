import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const { i18n, t } = useTranslation("global");
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const handleLanguageChange = (lang) => {
    if (lang !== currentLanguage) {
      i18n.changeLanguage(lang);
      localStorage.setItem("language", lang);
      setCurrentLanguage(lang);
    }
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "fr";
    if (savedLanguage !== currentLanguage) {
      i18n.changeLanguage(savedLanguage);
      setCurrentLanguage(savedLanguage);
    }
  }, [currentLanguage, i18n]);

  return (
    <LanguageContext.Provider value={{ t, handleLanguageChange,i18n }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
