import React, { useEffect, useState } from "react";
import "./contact.scss";
import { ctastar, images, verticleline } from "../../assets";
import { useLanguage } from "../../LanguageContext";
import { Link, useNavigate } from "react-router-dom";
import {
  bookingURL,
  contactThankYouURL,
  emailURL,
  mapDirection,
  phoneURL,
} from "../../helpers/constant";
import { AnimatedCta } from "../../components/AnimatedCta/AnimatedCta";
import config from "../../config/config";
import FR_errors from "../../joiLocales/validations_fr";
import http from "../../helpers/http";
import Joi from "joi";

const Contact = () => {
  // =============== VARIABLE & STATES  ===========================

  const [values, setValues] = useState({
    first_name: "",

    last_name: "",

    phone: "",
    email: "",
    special_request: "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { t, i18n } = useLanguage();

  const currentLang = i18n.resolvedLanguage;

  const base_url = config.api_url + "/forms/contact";
  const joiOptions = {
    abortEarly: false,
    allowUnknown: true,
    errors: {
      wrap: {
        label: "",
      },
      language: currentLang,
    },

    messages: {
      fr: { ...FR_errors },
    },
  };

  // =============== FUNCTIONS  ===========================

  const handleChange = (e) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const validate = async (data) => {
    let schemaObj = {
      first_name: Joi.string().required().label(t("booking.step3.fname")),
      last_name: Joi.string().required().label(t("booking.step3.lname")),
      phone: Joi.number().required().label(t("booking.step3.contactno")),
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .label(t("booking.step3.emailid")),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(data, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let form_values = values;

    let errors = await validate(form_values);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    const { data } = await http.post(`${base_url}`, form_values);

    if (data) {
      setValues({
        first_name: "",

        last_name: "",

        phone: "",
        email: "",
        special_request: "",
      });

      navigate(contactThankYouURL);
    }

    setLoading(false);
  };

  // =============== HTML RENDERING  ===========================
  // =============== DATA FETCHING  ===========================
  // =============== SIDE EFFECTS  ===========================

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <section className="contact_sec1">
        <img
          width={1920}
          height={1080}
          src={images.contactBg.img}
          alt="contact banner"
          className="contact_bg"
        />
        <div className="text_container">
          <h1 className="title">Contact</h1>
        </div>
      </section>

      <section className="contact_sec2">
        <div className="my_container">
          <h2 className="title">{t("contact.title")}</h2>
          <div className="form_flex">
            <div className="form_box">
              <form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form_field">
                      <div className="form_title">
                        {t("booking.step3.fname")}*
                      </div>
                      <input
                        type="text"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        className="form_input"
                      />

                      <p className="error_message">{formError.first_name}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form_field">
                      <div className="form_title">
                        {t("booking.step3.lname")}*
                      </div>
                      <input
                        type="text"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        className="form_input"
                      />
                      <p className="error_message">{formError.last_name}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form_field">
                      <div className="form_title">
                        {t("booking.step3.contactno")}*
                      </div>
                      <input
                        type="tel"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        className="form_input"
                      />
                      <p className="error_message">{formError.phone}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form_field">
                      <div className="form_title">
                        {t("booking.step3.emailid")}*
                      </div>
                      <input
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        className="form_input"
                      />
                      <p className="error_message">{formError.email}</p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form_field">
                      <div className="form_title">
                        {t("booking.step3.specialrequest")}
                      </div>
                      <textarea
                        rows={3}
                        name="special_request"
                        value={values.special_request}
                        onChange={handleChange}
                        className="form_input"
                      />
                      <p className="error_message">
                        {formError.special_request}
                      </p>
                    </div>
                  </div>
                </div>
                <button className="animated-cta">
                  <img
                    width={25}
                    height={25}
                    src={ctastar}
                    alt="start"
                    className="star"
                  />
                  <div className={`content withstar`}>
                    <div className="text">
                      {" "}
                      {loading
                        ? t("common.loading")
                        : t("contact.sendMsg")}{" "}
                    </div>
                    <div className="hover-text">
                      {" "}
                      {loading ? t("common.loading") : t("contact.sendMsg")}
                    </div>
                  </div>
                  <img
                    width={25}
                    height={25}
                    src={ctastar}
                    alt="start"
                    className="star star-inverse"
                  />
                </button>
              </form>
            </div>
            <div className="map_box">
              <div className="text_container">
                <h3 className="c_title">{t("contact.mapBox.helpTitle")}</h3>
              </div>
              <ul className="map_connect">
                <li className="list_item">
                  <h6 className="title">{t("contact.mapBox.title1")}</h6>
                  <Link to={phoneURL} className="anchor">
                    +33 6 36 57 25 92
                  </Link>
                </li>
                <li className="list_item">
                  <h6 className="title">{t("contact.mapBox.title2")}</h6>
                  <Link to={emailURL} className="anchor">
                    masdupontdelaberre@gmail.com
                  </Link>
                </li>
              </ul>
              <div className="map_container">
                <iframe
                  title="geo location"
                  className="map_frame"
                  src={mapDirection}
                  width="100%"
                  height="100%"
                  allowfullscreen="false"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="invite_container">
            <h4 className="invite_title">{t("contact.inviteTitle")}</h4>
            <img
              width={1}
              height={87}
              src={verticleline}
              alt="verticle"
              className="verticle_line"
              loading="lazy"
            />
            <AnimatedCta icons url={bookingURL} urlText={t("header.bookcta")} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
