  const errors = {

    
    'any.required': `La {{#label}} est obligatoire`,
    'string.email': '{{#label}} doit être une adresse e-mail valide',
    'string.empty': "{{#label}} il n'est pas permis d'être vide",
    'number.base': 'La {{#label}} doit être un numéro',
}


export default errors