

let config = {
  api_url: process.env.REACT_APP_API_URL,
  image_url: process.env.REACT_APP_IMAGE_URL,

  jwt_auth_key: "jwt_secret_storage_mas",
  jwt_remember_me: "jwt_remember_me",
  jwt_voter_auth_key: "jwt_voter_storage",
  app_mode: process.env.REACT_APP_APP_MODE,
  allowed_extensions: ["image/jpeg", "image/png", "image/svg+xml", "image/svg"],
  joiOptions: {
    abortEarly: false,
    allowUnknown: true,
    errors: {
      wrap: {
        label: "",
      },
    },
  },

};

export default config;
