import React, { useEffect } from "react";
import "./conditions.scss";
import { useLanguage } from "../../LanguageContext";

const Conditions = () => {
  const { t } = useLanguage();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <section className="conditions_sec1">
        <div className="my_container">
          <div className="text_container">
            <h1 className="title">{t("conditions.title")}</h1>
            <div className="box box1">
              <h2 className="subtitle">{t("conditions.box1.title")}</h2>
              <p
                className="desc"
                dangerouslySetInnerHTML={{ __html: t("conditions.box1.desc") }}
              />
            </div>
            <div className="box box2">
              <h2 className="subtitle">{t("conditions.box2.title")}</h2>
              <p
                className="desc"
                dangerouslySetInnerHTML={{ __html: t("conditions.box2.desc") }}
              />
            </div>
            <div className="box box3">
              <h2 className="subtitle">{t("conditions.box3.title")}</h2>
              <p
                className="desc"
                dangerouslySetInnerHTML={{ __html: t("conditions.box3.desc") }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Conditions;
