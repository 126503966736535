import React from "react";
import "./bookingconfirmed.scss";
import { thankyouicon } from "../../assets";
import { useLanguage } from "../../LanguageContext";
import helper from "../../helpers/helper";
import { roomsURL } from "../../helpers/constant";
import { AnimatedCta } from "../AnimatedCta/AnimatedCta";

function BookingConfirmed({ data }) {
  const { t } = useLanguage();

  return (
    <>
      <div className="confirmed_sec">
        <img
          width={100}
          height={100}
          src={thankyouicon}
          alt="Done icon"
          className="confirmed_icon"
        />
        <h1 className="title">{t("thankyou.title")}</h1>
        <p
          className="caption"
          dangerouslySetInnerHTML={{
            __html:
              "<strong>" +
              `${data.first_name} ${data.last_name}` +
              "</strong>" +
              ", " +
              t("thankyou.message1") +
              "  <strong>" +
              data.paypal_order_id +
              "</strong> " +
              t("thankyou.message2") +
              " <strong>" +
              helper.dateFormatter(data.start_date, "YYYY-MM-DD") +
              "</strong> " +
              t("thankyou.message3") +
              " <strong>" +
              helper.dateFormatter(data.end_date, "YYYY-MM-DD") +
              "</strong>. " +
              t("thankyou.message4"),
          }}
        />
        <p
          className="caption caption2"
          dangerouslySetInnerHTML={{ __html: t("thankyou.message5") }}
        />
        <div className="cta_wrapper">
          <AnimatedCta icons urlText={t("home.about.ctaText")} url={roomsURL} />
        </div>
      </div>
    </>
  );
}

export default BookingConfirmed;
