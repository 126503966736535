import React, { useEffect, useState } from "react";
import "./header.scss";
import { closeIcon, images, logo, logowhite } from "../../assets";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  bookingURL,
  contactURL,
  homeURL,
  locationURL,
  roomsURL,
} from "../../helpers/constant";
import LanguageSwitcher from "../../LanguageSwitcher";
import { useWidth } from "../../helpers/utils";
import { useLanguage } from "../../LanguageContext";
import { AnimatedCta } from "../AnimatedCta/AnimatedCta";

const navlistData = [
  images.homehead.img,
  images.roomshead.img,
  images.locationhead.img,
  images.contacthead.img,
];

const Header = () => {
  const [bookHead, setBookHead] = useState(false);
  const [glassmorph, setGlassmorph] = useState(false);
  const [solid, setSolid] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [mouseState, setMouseState] = useState(false);
  const [activeImg, setActiveImg] = useState(null);
  const navigate = useNavigate();
  const width = useWidth();
  const { t } = useLanguage();

  const pathName = window.location.pathname;

  const handleClose = () => {
    navigate(-1);
  };

  const toggleMenu = () => {
    setMenuActive((prev) => !prev);
  };

  useEffect(() => {
    if (menuActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [menuActive]);

  useEffect(() => {
    if (pathName !== homeURL && pathName !== contactURL) {
      setSolid(true);
    } else {
      setSolid(false);
    }

    if (pathName === bookingURL) {
      setBookHead(true);
    } else {
      setBookHead(false);
    }

    setMenuActive(false);
    setMouseState(false);

    const headerHandleScroll = () => {
      if (pathName !== homeURL && pathName !== contactURL) {
        setSolid(true);
      } else {
        setGlassmorph(window.pageYOffset > 0);
        setSolid(window.pageYOffset > 1080);
      }
    };

    window.addEventListener("scroll", headerHandleScroll);

    return () => {
      window.removeEventListener("scroll", headerHandleScroll);
    };
  }, [pathName]);

  return (
    <>
      <header className="header_sec">
        {!bookHead ? (
          <>
            <div
              className={`site_header ${solid && "solid"} ${
                glassmorph && "glassmorph"
              }`}
            >
              <div className="my_container">
                <div className="header_flex">
                  <nav className="navigation">
                    <div className="navlist">
                      <div
                        className={`hamburger ${solid && "black"} ${
                          menuActive && "active"
                        }`}
                        onClick={toggleMenu}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      <NavLink to={homeURL} className="list-item">
                        {t("header.navlist.home")}
                      </NavLink>
                      <NavLink to={roomsURL} className="list-item">
                        {t("header.navlist.rooms")}
                      </NavLink>
                      <NavLink to={locationURL} className="list-item">
                        {t("header.navlist.location")}
                      </NavLink>
                      <NavLink to={contactURL} className="list-item">
                        {t("header.navlist.contact")}
                      </NavLink>
                    </div>
                  </nav>
                  <Link to="/" className="logo_wrap">
                    <img
                      width={178}
                      height={81}
                      src={solid ? logo : logowhite}
                      alt="logo"
                      className="logo"
                    />
                  </Link>
                  <div className="cta_wrapper">
                    <LanguageSwitcher lightTheme={!solid ? true : false} />
                    <AnimatedCta
                      lightTheme={!solid ? true : false}
                      newClass="header_bookcta"
                      url={bookingURL}
                      urlText={
                        width > 992
                          ? t("header.bookcta")
                          : t("header.bookctamb")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {menuActive && <div className="outside" onClick={toggleMenu} />}
            <div className={`menu-box ${menuActive && "active"}`}>
              <div className="menu-inner">
                <div
                  className={`hamburger black ${menuActive && "active"}`}
                  onClick={toggleMenu}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="short_navlist">
                  <div className="eyebrow">Menu</div>
                  <NavLink
                    onMouseEnter={() => {
                      setActiveImg(navlistData[0]);
                      setMouseState(true);
                    }}
                    onMouseLeave={() => setMouseState(false)}
                    to={homeURL}
                    className="list-item"
                  >
                    {t("header.navlist.home")}
                  </NavLink>
                  <NavLink
                    onMouseEnter={() => {
                      setActiveImg(navlistData[1]);
                      setMouseState(true);
                    }}
                    onMouseLeave={() => setMouseState(false)}
                    to={roomsURL}
                    className="list-item"
                  >
                    {t("header.navlist.rooms")}
                  </NavLink>
                  <NavLink
                    onMouseEnter={() => {
                      setActiveImg(navlistData[2]);
                      setMouseState(true);
                    }}
                    onMouseLeave={() => setMouseState(false)}
                    to={locationURL}
                    className="list-item"
                  >
                    {t("header.navlist.location")}
                  </NavLink>
                  <NavLink
                    onMouseEnter={() => {
                      setActiveImg(navlistData[3]);
                      setMouseState(true);
                    }}
                    onMouseLeave={() => setMouseState(false)}
                    to={contactURL}
                    className="list-item"
                  >
                    {t("header.navlist.contact")}
                  </NavLink>
                </div>
              </div>
              {width > 992 && (
                <div className={`page_img_wrapper ${mouseState && "active"}`}>
                  <img
                    width={300}
                    height={1080}
                    src={activeImg}
                    alt="active"
                    className="page_img"
                    loading="lazy"
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <div className={`booking_header`}>
            <div className="my_container">
              <div className="booking_flex">
                {width > 600 && <div className="blank" />}
                <Link to="/" className="logo_wrap">
                  <img
                    width={178}
                    height={81}
                    src={logo}
                    alt="logo"
                    className="logo"
                  />
                </Link>
                <LanguageSwitcher />
              </div>
            </div>
            <div className="close_wrap" onClick={handleClose}>
              <img
                width={17}
                height={17}
                src={closeIcon}
                alt="close icon"
                className="close_icon"
              />
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
