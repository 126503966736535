import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Routing from "./Routing";
import BookingState from "./context/Booking/BookingState";
import SettingState from "./context/Setting/SettingState";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "swiper/css/effect-fade";
import Loader from "./components/Loader/Loader";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <BrowserRouter>
            <BookingState>
              <SettingState>
                <Routes>
                  <Route path="*" element={<Routing />} />
                </Routes>
              </SettingState>
            </BookingState>
          </BrowserRouter>
        </>
      )}
    </>
  );
}

export default App;
